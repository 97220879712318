<template>
  <div class="alterForm">
    <el-form :label-width="labelWidth" :label-position="labelPosition" :model="createDate" ref="alterForm">
      <!-- <el-collapse :value="collapseLength">
        <el-collapse-item
              v-for="(formItem, topIndex) in formData"
              :title="formItem.title"
              :name="formItem.name"
              :key="formItem.name"
              v-show="!formItem.visible || formItem.show"
            > -->
          <template v-for="item in formData">
            <el-col :xs="24" :sm="24" :md="24" :lg="item.span" :xl="item.span" :key="item.prop" v-if="(!item.Object.visible || (item.Object.visible && isGetVisible(item))) && ((item.Object.client && item.Object.client.includes(app_name)) || !item.Object.client)">
              <el-form-item :label-width="item.Object['label-width'] ? item.Object['label-width'] : null" :label="item.name !== 'array' ? item.label : ''" :prop="item.prop" :required="item.Object.required ? item.Object.required : false" :rules="item.Object.rules ? item.Object.rules : null">
                <div v-if="item.name === 'input' ">
                  <el-input :type="item.type ? item.type : 'text' "
                            :rows="item.Object.rows ? item.Object.rows : 0"
                            :placeholder="item.placeholder"
                            v-model="createDate[item.prop]"
                            @change="(data) => { return handleFirstChange(data, item) }"
                            :style="item.Object.style ? item.Object.style : '{ width : 100% }' "
                            :maxlength="item.Object.maxlength ? item.Object.maxlength : null"
                            :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                            :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                  >
                    <template v-if="item.Object.appendVisible" slot="append">{{item.Object.append}}</template>
                    <el-button v-if="item.Object.appendBtn" slot="append" :icon="item.Object.icon"></el-button>
                  </el-input>
                  <span class="label__wr" v-if="item.Object.label">{{item.Object.label}}</span>
                </div>
                <el-radio-group v-model="createDate[item.prop]"
                                v-if="item.name === 'group-radio' "
                                :disabled="isBoolean(item.Object['group-disabled']) ? item.Object['group-disabled'] : false"
                                :size="item.Object.size ? item.Object.size : 'small'"
                                :text-color="item.Object['text-color'] ? item.Object['text-color'] : '#ffffff'"
                                :fill="item.Object.fill ? item.Object.fill : '#409EFF'"
                                @change="(data) => { return handleFirstChange(data, item) }">
                  <el-radio v-for="option in item.options"
                            :border="isBoolean(item.Object.border) ? item.Object.border : false"
                            :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                            :key="option[item.Object.key ? item.Object.key : 'value']"
                            :label="option[item.Object.value ? item.Object.value : 'value']">
                            {{option[item.Object.label ? item.Object.label : 'name' ]}}
                            </el-radio>
                </el-radio-group>
                <p v-if="item.name === 'group-radio' && item.Object.extraText" style="margin: 0">{{item.Object.extraText}}</p>
                <template v-if="item.name === 'group-checkbox' ">
                  <el-checkbox v-if="isBoolean(item.Object.allCheck) ? item.Object.allCheck : 'false'" :indeterminate="isIndeterminate" v-model="checkAll" @change="(data) => { return handleCheckAllChange(data, item) }">全选</el-checkbox>
                  <el-checkbox-group  v-model="createDate[item.prop]"
                                      :min="item.Object.min ? item.Object.min : 0"
                                      :max="item.Object.max ? item.Object.max : 999"
                                      :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                                      @change="(data) => { return handleCheckedChange(data, item, createDate[item.prop]) }">
                    <el-checkbox  v-for="option in item.options"
                                  :key="option[item.Object.key ? item.Object.key : 'value']"
                                  :label="option[item.Object.value ? item.Object.value : 'value']"
                                  :disabled="isBoolean(option.disabled) ? option.disabled : false">
                                  {{option[item.Object.label ? item.Object.label : 'name' ]}}
                    </el-checkbox>
                  </el-checkbox-group>
                </template>
                <div v-if="item.name === 'select' ">
                  <el-select  @change="(data) => { return handleFirstChange(data, item) }"
                              v-model="createDate[item.prop]"
                              :placeholder="item.placeholder"
                              :style="item.Object.style ? item.Object.style : '{ width : 100% }' "
                              :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                              :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                              :multiple="isBoolean(item.Object.multiple) ? item.Object.multiple : false"
                              :collapse-tags="item.Object['collapse-tags'] ? item.Object['collapse-tags'] : false"
                              :filterable="isBoolean(item.Object.filterable) ? item.Object.filterable : false"
                              :multiple-limit="item.Object['multiple-limit'] ? item.Object['multiple-limit'] : 0"
                              :loading="isBoolean(item.Object.loading) ? item.Object.loading : false"
                              :loading-text="$t('message.loading')"
                              :reserve-keyword="isBoolean(item.Object['reserve-keyword']) ? item.Object['reserve-keyword'] : false"
                              :default-first-option="isBoolean(item.Object['default-first-option']) ? item.Object['default-first-option'] : false"
                              :remote="isBoolean(item.Object.remote) ? item.Object.remote : false"
                              :remote-method="remoteMethod">
                    <el-option
                      v-for="option in item.options"
                      :key="option[item.Object.key ? item.Object.key : 'value']"
                      :label="option[item.Object.name ? item.Object.name : 'name' ]"
                      :value="option[item.Object.value ? item.Object.value : 'value']"
                      :disabled="isBoolean(option.disabled) ? option.disabled : false">
                    </el-option>
                  </el-select>
                  <span class="label__wr" v-if="item.Object.label">{{item.Object.label}}</span>
                </div>
                <div v-if="item.name === 'image'">
                  <importImg  :limit="item.Object.limit ? item.Object.limit : 100"
                              :data="createDate[item.prop]"
                              :Object="item.Object"
                              :name="item.Object.imageName ? item.Object.imageName : 'image_url'"
                              :watchDataVisible="watchImageVisible"
                              @change="(file) => { return handleImageChange(file, item)}"></importImg>
                  <span>{{item.Object.size ? item.Object.size : ''}}</span>
                </div>
                <el-switch  v-if="item.name === 'switch'"
                            v-model="createDate[item.prop]"
                            @change="switchChanged($event, item.prop)"
                            :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                            :active-text="createDate[item.prop]? '' : item.Object['inactiveNotice']"
                            :active-value="isBoolean(item.Object['active-value']) ? item.Object['active-value'] : true"
                            :inactive-value="isBoolean(item.Object['inactive-value']) ? item.Object['inactive-value'] : false"></el-switch>
                <p v-if="item.Object.bottomNotice" class="botton-notice">{{item.Object.bottomNotice}}</p>
                <el-time-picker
                  v-if="item.name === 'time' "
                  v-model="createDate[item.prop]"
                  :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                  :placeholder="item.placeholder"
                  :format="item.Object['option-format'] ? item.Object['option-format']: 'HH:mm:ss'"
                  :value-format="item.Object.format ? item.Object.format: 'HH:mm:ss'"
                  :is-range="isBoolean(item.Object['is-range']) ? item.Object['is-range'] : false">
                </el-time-picker>
                <div v-if="item.name === 'checkbox'">
                  <el-checkbox  v-model="createDate[item.prop]">
                    {{768 > $store.state.documentWidth ? item.placeholder : ''}}
                  </el-checkbox>
                  <span class="label__wr" v-if="item.Object.label">{{item.Object.label}}</span>
                </div>

                <el-color-picker  v-if="item.name === 'color'"
                                  v-model="createDate[item.prop]"
                                  :show-alpha="isBoolean(item.Object['show-alpha']) ? item.Object['show-alpha'] : false"
                                  :color-format="item.Object['color-format'] ? item.Object['color-format'] : 'hex'">
                </el-color-picker>
                <el-date-picker  v-if="item.name === 'date'"
                                @change="(data) => { return handleFirstChange(data, item) }"
                                :type="item.type ? item.type : 'date'"
                                :placeholder="item.placeholder"
                                :start-placeholder="item.Object['start-placeholder'] ? item.Object['start-placeholder'] : ''"
                                :end-placeholder="item.Object['end-placeholder'] ? item.Object['end-placeholder'] : ''"
                                v-model="createDate[item.prop]"
                                :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : false"
                                :clearable="isBoolean(item.Object.clearable) ? item.Object.clearable : true"
                                :format="item.Object.format ? item.Object.format : 'yyyy-MM-dd'"
                                :value-format="item.Object['value-format'] ? item.Object['value-format'] : 'yyyy-MM-dd'">
                </el-date-picker>
                <component v-if="item.name === 'component'" :is="item.Object.name" :object="item" :value.sync="createDate[item.prop]" @isComplete="isComplete"></component>
                <div v-if="item.name === 'array'">
                  <template v-for="(dataItem, dataIndex) in createDate[item.prop]">
                  <div class="array__top" :key="'dataItem' + dataIndex" v-if="!dataItem[item.Object.delete]">
                      <span>{{`${dataIndex + 1}.${item.label}`}}</span>
                      <div>
                        <el-button icon="el-icon-plus" v-if="lodash.findLastIndex(createDate[item.prop], (item) => !item._destroy) === dataIndex" circle :disabled="item.Object.addFirstDisabled" @click="addFirstData(item.prop)"></el-button>
                        <el-button icon="el-icon-delete" circle type="danger" v-show="subFirstBtnVisible(item, dataIndex)" @click="subFirstData(item.prop, dataIndex, item.Object.delete)"></el-button>
                      </div>
                    </div>
                    <div class="arrayBoxBorder" :key="dataItem + dataIndex"  v-show="!dataItem[item.Object.delete]">
                      <template v-for="(firstItem, firstTopIndex) in item.createDateFirstItem">
                        <el-col :xs="24" :sm="24" :md="24" :lg="firstItem.span" :xl="firstItem.span" :key="item.prop + firstItem.prop"
                                v-if="!firstItem.Object.visible || (firstItem.Object.visible && isGetFirstVisible(firstItem, {'prop': item.prop, 'index': dataIndex, 'firstProp': firstItem.prop}))">
                          <el-form-item :label="firstItem.name !== 'array' ? firstItem.label : ''"
                                        :rules="firstItem.Object.rules ? firstItem.Object.rules : null"
                                        :prop="`${item.prop}.${dataIndex}.${firstItem.prop}`"
                                        :label-width="item.Object['array-label-width'] ? item.Object['array-label-width'] : null">
                            <div v-if="firstItem.name === 'input' ">
                              <el-input :type="firstItem.type ? firstItem.type : 'text' "
                                        :rows="firstItem.Object.rows ? firstItem.Object.rows : 0"
                                        :placeholder="firstItem.placeholder"
                                        @change="(data) => { return handleSecChange(data, firstItem, {prop: item.prop, index: dataIndex}) }"
                                        v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                        :style="firstItem.Object.style ? firstItem.Object.style : '{ width : 100% }' "
                                        :maxlength="firstItem.Object.maxlength ? firstItem.Object.maxlength : null"
                                        :clearable="isBoolean(firstItem.Object.clearable) ? firstItem.Object.clearable : true"
                                        :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                              >
                                <template v-if="firstItem.Object.appendVisible" slot="append">{{firstItem.Object.append}}</template>
                                 <el-button type="primary" v-if="firstItem.Object.appendBtn" slot="append" :icon="firstItem.Object.icon" @click="handleAppend(dataIndex)"></el-button>
                              </el-input>
                              <span class="label__wr" v-if="firstItem.Object.label">{{firstItem.Object.label}}</span>
                            </div>
                            <div v-if="firstItem.name === 'select' ">
                              <el-select  v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                          :placeholder="firstItem.placeholder"
                                          :style="firstItem.Object.style ? firstItem.Object.style : '{ width : 100% }' "
                                          :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                          :clearable="isBoolean(firstItem.Object.clearable) ? firstItem.Object.clearable : true"
                                          :multiple="isBoolean(firstItem.Object.multiple) ? firstItem.Object.multiple : false"
                                          :collapse-tags="isBoolean(firstItem.Object['collapse-tags']) ? firstItem.Object['collapse-tags'] : false"
                                          :filterable="isBoolean(firstItem.Object.filterable) ? firstItem.Object.filterable : false"
                                          :multiple-limit="firstItem.Object['multiple-limit'] ? firstItem.Object['multiple-limit'] : 0"
                                          :loading="isBoolean(firstItem.Object.loading) ? firstItem.Object.loading : false"
                                          :loading-text="$t('message.loading')"
                                          :reserve-keyword="isBoolean(firstItem.Object['reserve-keyword']) ? firstItem.Object['reserve-keyword'] : false"
                                          :default-first-option="isBoolean(firstItem.Object['default-first-option']) ? firstItem.Object['default-first-option'] : false"
                                          :remote="isBoolean(firstItem.Object.remote) ? firstItem.Object.remote : false"
                                          :remote-method="remoteMethod"
                                          @change="(data) => { return handleSecChange(data, firstItem, {prop: item.prop, index: dataIndex}) }">
                                <el-option
                                  v-for="option in firstItem.options"
                                  :key="option[firstItem.Object.key ? firstItem.Object.key : 'value']"
                                  :label="option[firstItem.Object.name ? firstItem.Object.name : 'name' ]"
                                  :value="option[firstItem.Object.value ? firstItem.Object.value : 'value']"
                                  :disabled="isBoolean(option.disabled) ? option.disabled : false">
                                </el-option>
                              </el-select>
                              <span class="label__wr" v-if="firstItem.Object.label">{{firstItem.Object.label}}</span>
                            </div>
                            <el-radio-group v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                v-if="firstItem.name === 'group-radio' "
                                :disabled="isBoolean(firstItem.Object['group-disabled']) ? firstItem.Object['group-disabled'] : false"
                                :size="firstItem.Object.size ? firstItem.Object.size : 'small'"
                                :text-color="firstItem.Object['text-color'] ? firstItem.Object['text-color'] : '#ffffff'"
                                :fill="firstItem.Object.fill ? firstItem.Object.fill : '#409EFF'"
                                @change="(data) => { return handleSecChange(data, firstItem, {prop: item.prop, index: dataIndex}) }">
                              <el-radio v-for="option in firstItem.options"
                                        :border="isBoolean(firstItem.Object.border) ? firstItem.Object.border : false"
                                        :disabled="isBoolean(item.Object.disabled) ? firstItem.Object.disabled : false"
                                        :key="option[firstItem.Object.key ? firstItem.Object.key : 'value']"
                                        :label="option[firstItem.Object.value ? firstItem.Object.value : 'value']">
                                        {{option[firstItem.Object.label ? firstItem.Object.label : 'name' ]}}
                                        </el-radio>
                            </el-radio-group>
                            <div v-if="firstItem.name === 'image'">
                              <importImg  :limit="firstItem.Object.limit ? firstItem.Object.limit : 100"
                                          :data="createDate[item.prop][dataIndex][firstItem.prop]"
                                          :Object="firstItem.Object"
                                          :name="firstItem.Object.imageName ? firstItem.Object.imageName : 'image_url'"
                                          :watchDataVisible="false"
                                          @change="(file) => { return handleFirstImageChange(file, item.prop, dataIndex, firstItem.prop, firstItem)}"></importImg>
                              <span>{{firstItem.Object.size ? firstItem.Object.size : ''}}</span>
                            </div>
                            <el-switch  v-if="firstItem.name === 'switch'"
                                        :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                        v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                        :active-value="isBoolean(firstItem.Object['active-value']) ? firstItem.Object['active-value'] : true"
                                        :inactive-value="isBoolean(firstItem.Object['inactive-value']) ? firstItem.Object['inactive-value'] : false"
                            ></el-switch>
                            <el-time-picker
                              v-if="firstItem.name === 'time' "
                              :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                              v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                              :clearable="isBoolean(firstItem.Object.clearable) ? firstItem.Object.clearable : true"
                              :placeholder="firstItem.placeholder"
                              :value-format="firstItem.Object.format ? firstItem.Object.format: 'HH:mm:ss'">
                            </el-time-picker>
                            <el-checkbox  v-if="firstItem.name === 'checkbox'"
                                          :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                          v-model="createDate[item.prop][dataIndex][firstItem.prop]">
                                          {{768 > $store.state.documentWidth ? firstItem.placeholder : ''}}
                            </el-checkbox>
                            <el-color-picker  v-if="firstItem.name === 'color'"
                                              :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                              v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                              :show-alpha="isBoolean(firstItem.Object['show-alpha']) ? firstItem.Object['show-alpha'] : false"
                                              :color-format="firstItem.Object['color-format'] ? firstItem.Object['color-format'] : 'hex'">
                            </el-color-picker>
                            <el-date-picker  v-if="firstItem.name === 'date'"
                                              @change="(data) => { return handleFirstChange(data, firstItem, createDate, {'prop': item.prop, 'index': dataIndex, 'firstProp': firstItem.prop }) }"
                                              :type="firstItem.type ? firstItem.type : 'date'"
                                              :placeholder="firstItem.placeholder"
                                              :start-placeholder="firstItem.Object['start-placeholder'] ? firstItem.Object['start-placeholder'] : ''"
                                              :end-placeholder="firstItem.Object['end-placeholder'] ? firstItem.Object['end-placeholder'] : ''"
                                              v-model="createDate[item.prop][dataIndex][firstItem.prop]"
                                              :disabled="isBoolean(item.Object.disabled) ? item.Object.disabled : isBoolean(firstItem.Object.disabled) ? firstItem.Object.disabled : isBoolean(createDate[item.prop][dataIndex].disabled) ? createDate[item.prop][dataIndex].disabled : false"
                                              :clearable="isBoolean(firstItem.Object.clearable) ? firstItem.Object.clearable : true"
                                              :format="firstItem.Object.format ? firstItem.Object.format : 'yyyy-MM-dd'"
                                              :value-format="firstItem.Object['value-format'] ? firstItem.Object['value-format'] : 'yyyy-MM-dd'">
                            </el-date-picker>
                            <component v-if="firstItem.name === 'component'" :is="firstItem.Object.name" :object="firstItem" :value.sync="createDate[item.prop][dataIndex][firstItem.prop]"></component>
                            <div v-if="firstItem.name === 'array'">
                              <template v-for="(dataSeconedItem, dataSeconedIndex) in createDate[item.prop][dataIndex][firstItem.prop]">
                                <div class="array__top" :key="'dataSeconedItem' + dataSeconedIndex" v-if="!dataSeconedItem[firstItem.Object.delete]">
                                <span>{{`${dataSeconedIndex + 1}.${firstItem.label}`}}</span>
                                <div>
                                  <el-button icon="el-icon-plus" v-if="lodash.findLastIndex(createDate[item.prop][dataIndex][firstItem.prop], (secitem) => !secitem._destroy) === dataSeconedIndex" circle @click="addSeconedData(item.prop, firstTopIndex, dataIndex, firstItem.prop)"></el-button>
                                  <el-button icon="el-icon-delete" circle type="danger" @click="subSeconedData(item.prop, dataIndex, firstItem.prop, dataSeconedIndex, firstItem.Object.delete)"></el-button>
                                </div>
                              </div>
                                <div class="arrayBoxBorder" :key="dataSeconedItem + dataSeconedIndex" v-show="!dataSeconedItem[firstItem.Object.delete]">
                                  <el-col v-for="seconedItem in firstItem.createDateFirstItem" :xs="24" :sm="24" :md="24" :lg="seconedItem.span" :xl="seconedItem.span" :key="firstItem.prop + seconedItem.prop">
                                    <el-form-item :rules="seconedItem.Object.rules ? seconedItem.Object.rules : null"
                                                  :label="seconedItem.label" :prop="`${item.prop}.${dataIndex}.${firstItem.prop}.${dataSeconedIndex}.${seconedItem.prop}`"
                                                  :label-width="firstItem.Object['array-label-width'] ? firstItem.Object['array-label-width'] : null"
                                                  v-show="!seconedItem.Object.visible || (seconedItem.Object.visible && isGetSeconedVisible(seconedItem , {'prop': item.prop, 'index': dataIndex, 'firstProp': firstItem.prop, 'seconedIndex': dataSeconedIndex, 'seconedProp': seconedItem.prop}))">
                                      <el-input v-if="seconedItem.name === 'input' "
                                                :type="seconedItem.type ? seconedItem.type : 'text' "
                                                :rows="seconedItem.Object.rows ? seconedItem.Object.rows : 0"
                                                :placeholder="seconedItem.placeholder"
                                                @change="(data) => { return handleFirstChange(data, seconedItem) }"
                                                v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                                :style="seconedItem.Object.style ? seconedItem.Object.style : '{ width : 100% }' "
                                                :maxlength="seconedItem.Object.maxlength ? seconedItem.Object.maxlength : null"
                                                :clearable="isBoolean(seconedItem.Object.clearable) ? seconedItem.Object.clearable : true"
                                                :disabled="isBoolean(seconedItem.Object.disabled) ? seconedItem.Object.disabled : false"
                                      >
                                        <template v-if="seconedItem.Object.appendVisible" slot="append">{{seconedItem.Object.append}}</template>
                                      </el-input>
                                      <el-select  v-if="seconedItem.name === 'select' "
                                                  v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                                  :placeholder="seconedItem.placeholder"
                                                  :style="seconedItem.Object.style ? seconedItem.Object.style : '{ width : 100% }' "
                                                  :disabled="isBoolean(seconedItem.Object.disabled) ? seconedItem.Object.disabled : false"
                                                  :clearable="isBoolean(seconedItem.Object.clearable) ? seconedItem.Object.clearable : true"
                                                  :multiple="isBoolean(seconedItem.Object.multiple) ? seconedItem.Object.multiple : false"
                                                  :collapse-tags="isBoolean(seconedItem.Object['collapse-tags']) ? seconedItem.Object['collapse-tags'] : false"
                                                  :filterable="isBoolean(seconedItem.Object.filterable) ? seconedItem.Object.filterable : false"
                                                  :multiple-limit="seconedItem.Object['multiple-limit'] ? seconedItem.Object['multiple-limit'] : 0"
                                                  :loading="isBoolean(seconedItem.Object.loading) ? seconedItem.Object.loading : false"
                                                  :loading-text="$t('message.loading')"
                                                  :reserve-keyword="isBoolean(seconedItem.Object['reserve-keyword']) ? seconedItem.Object['reserve-keyword'] : false"
                                                  :default-first-option="isBoolean(seconedItem.Object['default-first-option']) ? seconedItem.Object['default-first-option'] : false"
                                                  :remote="isBoolean(seconedItem.Object.remote) ? seconedItem.Object.remote : false"
                                                  :remote-method="remoteMethod"
                                                  @change="(data) => { return handleFirstChange(data, seconedItem) }">
                                        <el-option
                                          v-for="option in seconedItem.options"
                                          :key="option.value"
                                          :label="option.name"
                                          :value="option.value"
                                          :disabled="isBoolean(option.disabled) ? option.disabled : false">
                                        </el-option>
                                      </el-select>
                                      <div v-if="seconedItem.name === 'image'">
                                        <importImg  :limit="seconedItem.Object.limit ? seconedItem.Object.limit : 100"
                                                    :data="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                                    :Object="seconedItem.Object"
                                                    :name="seconedItem.Object.imageName ? seconedItem.Object.imageName : 'image_url'"
                                                    :watchDataVisible="false"
                                                    @change="(file) => { return handleSeconedImageChange(file, item.prop, dataIndex, firstItem.prop, dataSeconedIndex, seconedItem.prop, seconedItem)}"></importImg>
                                        <span>{{seconedItem.Object.size ? seconedItem.Object.size : ''}}</span>
                                      </div>
                                      <el-switch  v-if="seconedItem.name === 'switch'"
                                                  v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                                  :active-value="isBoolean(seconedItem.Object['active-value']) ? seconedItem.Object['active-value'] : true"
                                                  :inactive-value="isBoolean(seconedItem.Object['inactive-value']) ? seconedItem.Object['inactive-value'] : false"
                                      ></el-switch>
                                      <el-time-picker
                                        v-if="seconedItem.name === 'time' "
                                        v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                        :clearable="isBoolean(seconedItem.Object.clearable) ? seconedItem.Object.clearable : true"
                                        :placeholder="seconedItem.placeholder"
                                        :value-format="seconedItem.Object.format ? seconedItem.Object.format: 'HH:mm:ss'">
                                      </el-time-picker>
                                      <el-checkbox  v-if="seconedItem.name === 'checkbox'"
                                                    v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]">{{seconedItem.placeholder}}</el-checkbox>
                                      <el-color-picker  v-if="seconedItem.name === 'color'"
                                                        v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                                        :show-alpha="isBoolean(seconedItem.Object['show-alpha']) ? seconedItem.Object['show-alpha'] : false"
                                                        :color-format="seconedItem.Object['color-format'] ? seconedItem.Object['color-format'] : 'hex'">
                                      </el-color-picker>
                                      <el-date-picker  v-if="seconedItem.name === 'date'"
                                                      :type="seconedItem.type ? seconedItem.type : 'date'"
                                                      :placeholder="seconedItem.placeholder"
                                                      :start-placeholder="seconedItem.Object['start-placeholder'] ? seconedItem.Object['start-placeholder'] : ''"
                                                      :end-placeholder="seconedItem.Object['end-placeholder'] ? seconedItem.Object['end-placeholder'] : ''"
                                                      v-model="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"
                                                      :disabled="isBoolean(seconedItem.Object.disabled) ? seconedItem.Object.disabled : false"
                                                      :clearable="isBoolean(seconedItem.Object.clearable) ? seconedItem.Object.clearable : true"
                                                      :format="seconedItem.Object.format ? seconedItem.Object.format : 'yyyy-MM-dd'"
                                                      :value-format="seconedItem.Object['value-format'] ? seconedItem.Object['value-format'] : 'yyyy-MM-dd'">
                                      </el-date-picker>
                                      <component v-if="seconedItem.name === 'component'" :is="seconedItem.Object.name" :object="seconedItem" :value.sync="createDate[item.prop][dataIndex][firstItem.prop][dataSeconedIndex][seconedItem.prop]"></component>
                                    </el-form-item>
                                  </el-col>
                                  <!-- <el-col :span="24">
                                    <el-button type="danger" @click="subSeconedData(item.prop, dataIndex, firstItem.prop, dataSeconedIndex, firstItem.Object.delete)">{{$t('button.delete')}}</el-button>
                                  </el-col> -->
                                </div>
                              </template>
                              <!-- <el-form-item style="display: inline-table;">
                                <el-button icon="el-icon-plus" @click="addSeconedData(item.prop, firstTopIndex, dataIndex, firstItem.prop)">{{ $t('button.add') }}</el-button>
                              </el-form-item> -->
                              <div class="array__top array__bottom" v-if="createDate[item.prop][dataIndex][firstItem.prop].length === 0 || createDate[item.prop][dataIndex][firstItem.prop].every(firitem => firitem._destroy)">
                                <span>{{firstItem.label}}</span>
                                <div>
                                  <el-button icon="el-icon-plus" circle @click="addSeconedData(item.prop, firstTopIndex, dataIndex, firstItem.prop)"></el-button>
                                </div>
                              </div>
                            </div>
                          </el-form-item>
                        </el-col>
                      </template>
                      <!-- <el-col :span="24">
                        <el-button type="danger" v-show="subFirstBtnVisible(item, dataIndex)" @click="subFirstData(item.prop, dataIndex, item.Object.delete)">{{$t('button.delete')}}</el-button>
                      </el-col> -->
                    </div>
                  </template>
                  <!-- <el-form-item style="display: inline-table;" > -->
                    <div class="array__top array__bottom" v-if="createDate[item.prop].length === 0 || createDate[item.prop].every(item => item._destroy)">
                      <span>{{item.label}}</span>
                      <div>
                        <el-button icon="el-icon-plus"  circle :disabled="item.Object.addFirstDisabled" @click="addFirstData(item.prop)"></el-button>
                      </div>
                    </div>
                  <!-- </el-form-item> -->
                </div>
              </el-form-item>
            </el-col>
          </template>
        <!-- </el-collapse-item>
      </el-collapse> -->
        <!-- <el-row v-if="!customeButton" type="flex" justify="center"> -->
          <!-- <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleSubmitClick">{{ $t('button.confirm') }}</el-button> -->
        <!-- </el-row> -->
        <!-- <el-row v-else type="flex" justify="center">
          <slot name="customeButton" :data="createDate"></slot>
        </el-row> -->
    </el-form>
    <!-- <el-row>
      <el-col :span="24"> -->
        <div v-if="!customeButton" class="footer__btn">
          <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleSubmitClick">{{ $t('button.confirm') }}</el-button>
        </div>
      <!-- </el-col>
    </el-row> -->
  </div>
</template>

<script>
import importImg from '@/components/importImg'
import Utils from '@/utils/Utils'
import lodash from 'lodash'

export default {
  components: {
    importImg
  },
  props: {
    labelPosition: {
      type: String,
      default () {
        return 'left'
      }
    },
    labelWidth: {
      type: String,
      default () {
        return '240px'
      }
    },
    watchImageVisible: {
      type: Boolean,
      default () {
        return true
      }
    },
    formData: {
      type: Array,
      default () {
        return []
      }
    },
    collapseLength: {
      type: Array,
      default () {
        return []
      }
    },
    modifyData: {
      type: Object,
      default () {
        return {}
      }
    },
    customeButton: {
      type: Boolean,
      default () {
        return false
      }
    },
    addFirstArray: {
      type: Boolean,
      default () {
        return false
      }
    },
    addSeconedArray: {
      type: Boolean,
      default () {
        return false
      }
    },
    subFirstArray: {
      type: Boolean,
      default () {
        return false
      }
    },
    validateBeforeVisible: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  watch: {
    modifyData: {
      deep: true,
      immediate: true,
      handler (n) {
        this.$nextTick(() => {
          const obj = JSON.parse(JSON.stringify(n))
          Object.assign(this.createDate, obj)
        })
      }
    }
  },
  data () {
    return {
      alterData: {},
      createDate: {},
      lodash: lodash,
      recordData: null,
      recordSecData: null,
      recordThrData: null,
      checkAll: false,
      isIndeterminate: true,
      app_name: process.env.VUE_APP_NAME
    }
  },
  created () {
    this.init()
  },
  methods: {
    getArrayBtnVisible (prop, index) {
      // console.log(this.createDate[prop].reverse())
      // const cindex = this.createDate[prop].reverse().findIndex(item => !item._destroy)
      // if (cindex === index) return true
    },
    // 控制一层删除按钮显示
    subFirstBtnVisible (item, index) {
      const length = this.createDate[item.prop].length
      let inedxTaget = length - 1
      for (let i = this.createDate[item.prop].length - 1; i > 0; i--) {
        if (i === inedxTaget && this.createDate[item.prop][i]._destroy) {
          inedxTaget = inedxTaget - 1
        }
      }
      if (item.Object.lastVisible) {
        if (inedxTaget === index) return true
        else return false
      } else {
        return true
      }
    },
    isGetVisible (item) {
      if (item.Object.visibleExits && this.isNotNull(this.createDate[item.Object.visible])) {
        return true
      } else if (item.Object.visibleExits) {
        return false
      }
      if (Utils.isNull(item.Object.visibleValue) && this.isNotNull(this.createDate[item.Object.visible])) {
        return true
      }
      let bool_string = null
      if (this.createDate[item.Object.visible] === null || this.createDate[item.Object.visible] === undefined) {
        bool_string = false
      } else {
        bool_string = !(this.createDate[item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[item.Object.visible].toString())
      }
      const bool_array = (this.createDate[item.Object.visible] instanceof Array) && this.createDate[item.Object.visible].includes(item.Object.visibleValue)
      return bool_string || bool_array
    },
    isGetFirstVisible (item, object) {
      if (Utils.isNull(item.Object.visibleValue) && (this.isNotNull(this.createDate[item.Object.visible]) || this.isNotNull(this.createDate[object.prop][object.index][item.Object.visible]))) {
        return true
      }
      let bool_string = null
      if (this.createDate[item.Object.visible]) {
        if (this.createDate[item.Object.visible] === null || this.createDate[item.Object.visible] === undefined) {
          bool_string = false
        } else {
          bool_string = !(this.createDate[item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[item.Object.visible].toString())
        }
      } else {
        if (this.createDate[object.prop][object.index][item.Object.visible] === null || this.createDate[object.prop][object.index][item.Object.visible] === undefined) {
          bool_string = false
        } else {
          bool_string = !(this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[object.prop][object.index][item.Object.visible].toString())
        }
      }
      let bool_array = null
      if (this.createDate[item.Object.visible]) {
        bool_array = (this.createDate[item.Object.visible] instanceof Array) && this.createDate[item.Object.visible].includes(item.Object.visibleValue)
      } else {
        bool_array = (this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][item.Object.visible].includes(item.Object.visibleValue)
      }
      // const bool_string = !(this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][item.Object.visible].toString().split(',').includes(item.Object.visibleValue.toString())
      // const bool_array = (this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][item.Object.visible].includes(item.Object.visibleValue)
      return bool_string || bool_array
    },
    isGetSeconedVisible (item, object) {
      if (Utils.isNull(item.Object.visibleValue) && (this.isNotNull(this.createDate[item.Object.visible]) || this.isNotNull(this.createDate[object.prop][object.index][item.Object.visible]) || this.isNotNull(this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible]))) {
        return true
      }
      let bool_string = null
      if (this.createDate[item.Object.visible]) {
        if (this.createDate[item.Object.visible] === null || this.createDate[item.Object.visible] === undefined) {
          bool_string = false
        } else {
          bool_string = !(this.createDate[item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[item.Object.visible].toString())
        }
      } else if (this.createDate[object.prop][object.index][item.Object.visible]) {
        if (this.createDate[object.prop][object.index][item.Object.visible] === null || this.createDate[object.prop][object.index][item.Object.visible] === undefined) {
          bool_string = false
        } else {
          bool_string = !(this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[object.prop][object.index][item.Object.visible].toString())
        }
      } else {
        if (this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible] === null || this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible] === undefined) {
          bool_string = false
        } else {
          bool_string = !(this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible] instanceof Array) && item.Object.visibleValue.toString().split(',').includes(this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible].toString())
        }
      }
      let bool_array = null
      if (this.createDate[item.Object.visible]) {
        bool_array = (this.createDate[item.Object.visible] instanceof Array) && this.createDate[item.Object.visible].includes(item.Object.visibleValue)
      } else if (this.createDate[object.prop][object.index][item.Object.visible]) {
        bool_array = (this.createDate[object.prop][object.index][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][item.Object.visible].includes(item.Object.visibleValue)
      } else {
        bool_array = (this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible] instanceof Array) && this.createDate[object.prop][object.index][object.firstProp][object.seconedIndex][item.Object.visible].includes(item.Object.visibleValue)
      }
      return bool_string || bool_array
    },
    isBoolean (data) {
      if (data || typeof data === 'boolean') return true
      else return false
    },
    isNotNull (data) {
      if (data && ((data instanceof Array && data.length > 0) || (!(data instanceof Array) && data !== ''))) {
        return true
      } else {
        return false
      }
    },
    init () {
      const formNextData = JSON.parse(JSON.stringify(this.formData))
      formNextData.forEach(res => {
        if ((res.Object.client && res.Object.client.includes(this.app_name)) || !res.Object.client) {
          this.$set(this.createDate, res.prop, res.value || res.value === false || res.value === 0 ? res.value : '')
        }
      })
    },
    handleFirstChange (data, item) {
      if (!item.Object.nextChange) return
      if (item.Object.isAll && !this.recordData && data.length > 1 && data.includes(item.Object.isAllValue)) {
        const number = this.createDate[item.prop].indexOf(item.Object.isAllValue)
        this.createDate[item.prop].splice(number, 1)
      } else if (item.Object.isAll && data.length === 1 && data.includes(item.Object.isAllValue)) {
        this.createDate[item.prop] = [item.Object.isAllValue]
      } else if (item.Object.isAll && data.length > 1 && !this.recordData.includes(item.Object.isAllValue) && data.includes(item.Object.isAllValue)) {
        this.createDate[item.prop] = [item.Object.isAllValue]
      } else if (item.Object.isAll && data.length > 1 && this.recordData.includes(item.Object.isAllValue && data.includes(item.Object.isAllValue))) {
        const number = this.createDate[item.prop].indexOf(item.Object.isAllValue)
        this.createDate[item.prop].splice(number, 1)
      }
      this.recordData = data
      this.$emit('handleChange', data, item.Object.nextChangeProp, item, this.createDate)
    },
    handleSecChange (data, item, object) {
      if (!item.Object.nextChange) return
      if (item.Object.isAll && !this.recordSecData && data.length > 1 && data.includes(item.Object.isAllValue)) {
        const number = this.createDate[object.prop][object.index][item.prop].indexOf(item.Object.isAllValue)
        this.createDate[object.prop][object.index][item.prop].splice(number, 1)
      } else if (item.Object.isAll && data.length === 1 && data.includes(item.Object.isAllValue)) {
        this.createDate[object.prop][object.index][item.prop] = [item.Object.isAllValue]
      } else if (item.Object.isAll && data.length > 1 && !this.recordSecData.includes(item.Object.isAllValue) && data.includes(item.Object.isAllValue)) {
        this.createDate[object.prop][object.index][item.prop] = [item.Object.isAllValue]
      } else if (item.Object.isAll && data.length > 1 && this.recordSecData.includes(item.Object.isAllValue && data.includes(item.Object.isAllValue))) {
        const number = this.createDate[object.prop][object.index][item.prop].indexOf(item.Object.isAllValue)
        this.createDate[object.prop][object.index][item.prop].splice(number, 1)
      }
      this.recordSecData = data
      this.$emit('handleChange', data, item.Object.nextChangeProp, item, this.createDate, object)
    },
    subFirstData (prop, index, mark) {
      if (this.subFirstArray) {
        this.$emit('handleSubFirstArray', this.createDate, { prop, index, mark })
        return
      }
      if (this.createDate[prop][index].id) {
        this.$set(this.createDate[prop][index], mark, true)
      } else {
        this.createDate[prop].splice(index, 1)
      }
      this.$emit('formSubmit', this.createDate)
    },
    addFirstData (prop) {
      const formNextData = JSON.parse(JSON.stringify(this.formData))
      const obj = formNextData.filter(item => item.prop === prop)[0]
      this.alterData = {}
      obj.createDateFirstItem.forEach(res => {
        this.$set(this.alterData, res.prop, res.value ? res.value : '')
      })
      if (this.addFirstArray) {
        this.$emit('handleAddFirstArray', this.createDate, this.alterData, { prop })
        return
      }
      this.createDate[prop].push(this.alterData)
      this.$emit('formSubmit', this.createDate)
    },
    subSeconedData (prop, index, firstProp, firstIndex, mark) {
      if (this.createDate[prop][index][firstProp][firstIndex].id) {
        this.$set(this.createDate[prop][index][firstProp][firstIndex], mark, true)
      } else {
        this.createDate[prop][index][firstProp].splice(firstIndex, 1)
      }
      this.$emit('formSubmit', this.createDate)
    },
    addSeconedData (prop, firstTopIndex, firstIndex, firstProp) {
      const formNextData = JSON.parse(JSON.stringify(this.formData))
      const obj = formNextData.filter(item => item.prop === prop)[0]
      const nextObj = obj.createDateFirstItem[firstTopIndex]
      this.alterData = {}
      nextObj.createDateFirstItem.forEach(res => {
        this.$set(this.alterData, res.prop, res.value ? res.value : '')
      })
      if (this.addSeconedArray) {
        this.$emit('handleAddSeconedArray', this.createDate, this.alterData, { prop, index: firstIndex, firstProp })
        return
      }
      this.createDate[prop][firstIndex][firstProp].push(this.alterData)
      this.$emit('formSubmit', this.createDate)
    },
    remoteMethod (query, api, item) {
      if (query !== '') {
        setTimeout(() => {
          api(query).then(res => {
            this.$emit('remoteMethod', res, item)
          })
        }, 500)
      } else {
        this.$emit('remoteMethod', null, item)
      }
    },
    handleImageChange (data, item) {
      if (data && data.length > 0 && !data[0]._destroy && item.Object.limit === 1) {
        this.createDate[item.prop] = data[0].url
      } else if (data && data.length > 0 && item.Object.limit > 1) {
        this.createDate[item.prop] = data.map(item => {
          return {
            id: item.id,
            remote_image_url: item.url,
            _destroy: !!item._destroy,
            display_order: item.display_order
          }
        })
      } else if (item.Object.limit > 1) {
        this.createDate[item.prop] = []
      } else {
        this.createDate[item.prop] = null
      }
    },
    handleSeconedImageChange (data, prop, index, lastProp, secondIndex, secondProp, item) {
      if (data && data.length > 0 && !data[0]._destroy && item.Object.limit === 1) {
        this.createDate[prop][index][lastProp][secondIndex][secondProp] = data[0].url
      } else if (data && data.length > 0 && item.Object.limit > 1) {
        // console.log(data)
        this.createDate[prop][index][lastProp][secondIndex][secondProp] = data.map(item => {
          return {
            id: item.id,
            remote_image_url: item.url,
            _destroy: !!item._destroy,
            display_order: item.display_order
          }
        })
      } else if (item.Object.limit > 1) {
        this.createDate[prop][index][lastProp][secondIndex][secondProp] = []
      } else {
        this.createDate[prop][index][lastProp][secondIndex][secondProp] = null
      }
    },
    handleFirstImageChange (data, prop, index, lastProp, item) {
      if (data && data.length > 0 && !data[0]._destroy && item.Object.limit === 1) {
        this.createDate[prop][index][lastProp] = data[0].url
      } else if (data && data.length > 0 && item.Object.limit > 1) {
        this.createDate[prop][index][lastProp] = data.map(item => {
          return {
            id: item.id,
            remote_image_url: item.url,
            _destroy: !!item._destroy,
            display_order: item.display_order
          }
        })
        // console.log('>>>>2', this.createDate[prop][index][lastProp])
      } else if (item.Object.limit > 1) {
        this.createDate[prop][index][lastProp] = []
      } else {
        this.createDate[prop][index][lastProp] = null
      }
    },
    handleSubmitClick () {
      if (this.validateBeforeVisible) {
        this.$emit('validateBefore', this.createDate)
      }
      this.$refs.alterForm.validate((valid) => {
        if (valid) {
          this.$emit('formSubmit', this.createDate)
        } else {
          this.$message.error(this.$t('message.formIsRequire'))
          return false
        }
      })
    },
    isComplete (data) {
      this.$emit('isComplete', data, this.createDate)
    },
    handleCancelClick () {
      this.$emit('close')
      this.$router.go(-1)
    },
    // 操作第一层checkbox
    handleCheckAllChange (val, item) {
      const arr = []
      item.options.forEach(res => {
        arr.push(res[item.Object.value ? item.Object.value : 'value'])
      })
      this.createDate[item.prop] = val ? arr : []
      // console.log(data, this.createDate)
      this.isIndeterminate = false
    },
    handleCheckedChange (val, item) {
      const checkedCount = val.length
      this.checkAll = checkedCount === item.options.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < item.options.length
      this.handleFirstChange(val, item)
    },
    switchChanged (value, prop) {
      this.$emit('switchChanged', value, this.createDate, prop)
    },
    resetForm () {
      this.$nextTick(() => {
        this.$refs.alterForm.resetFields()
      })
    },
    handleAppend (index) {
      this.$emit('handleAppend', index)
    }
  }
}
</script>

<style lang="scss" scope>
  .label__wr {
    display: block;
    word-break: break-word;
    line-height: 18px;
    margin-top: 8px;
    font-size: 12px;
    color: #999;
  }
  .el-form {
    display: inline-block;
    width: 100%;
  }
  .el-select {
    width: 100%;
  }
  .el-input {
    width: 100% !important;
  }
  .arrayBoxBorder {
    border: 1px solid #EBEEF5;
    padding: 12px;
    display: flow-root;
    margin-bottom: 12px;
    border-radius: 0 0 10px 10px;
    // &:last {
    //   margin-bottom: 0 !important;
    // }
  }
  .el-date-editor--daterange {
    width: 100% !important;
  }
  .el-date-editor--time {
    width: 100% !important;
  }
  .array__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(228, 231, 235);
    padding: 10px 8px;
    font-weight: 600;
    font-size: .8rem;
    border-radius: 10px 10px 0 0;
  }
  .array__bottom {
    border-radius: 10px;
  }
  .botton-notice{
    margin: 0;
    color: #999;
    font-size: 12px;
    line-height: 14px;
  }
  .el-checkbox, .el-checkbox__input {
    white-space: normal;
    // word-break: break-all;
  }
  .el-date-editor--datarange {
    width: 100% !important;
  }
  .footer__btn {
    text-align: center;
  }
  // @media screen and (max-width: 1200px) {
  //   grid-template-columns: repeat(2, $var-box-pad-width);
  // }
  @media screen and (max-width: 768px) {
    .el-form-item__label {
      display: none;
      width: 0 !important;
    }
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
</style>
